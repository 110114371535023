.default-table {
  td {
    white-space: nowrap;
  }

  td,
  th {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.login-page {
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden;

  .right-side-wrapper {
    min-height: unset !important;
  }
}

.sidebar-menu-group-with-badge {
  width: fit-content !important;
  height: fit-content !important;
  transition: color 0.5s;
  color: inherit !important;
}

.datepicker-no-input {
  display: none !important;
}

.datepicker-no3dfx .ant-picker-panel-container {
  box-shadow: none !important;
}

.datepicker-no3dfx .ant-picker-range-wrapper .ant-picker-panel-container {
  box-shadow: none !important;
}

.datepicker-no3dfx .ant-picker-range-wrapper .ant-picker-range-arrow {
  display: none !important;
}

table.studyplan-summary {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
}

table.studyplan-summary td {
  padding: 5px;
  border-radius: 4px;
  min-width: 150px;
  max-width: 150px;
  width: 150px;
}

table.studyplan-summary td:nth-child(2) {
  background-color: rgb(244, 244, 244);
  text-align: left;
  min-width: 90px;
  max-width: 90px;
  width: 90px;
}

table.studyplan-summary td:nth-child(3) {
  min-width: 20px;
  text-align: center;
}

table.studyplan-summary td:nth-child(4) {
  background-color: rgb(244, 244, 244);
  text-align: left;
  min-width: 90px;
  max-width: 90px;
  width: 90px;
}

.required-element::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-picker-calendar-mode-switch {
  display: none !important;
}
