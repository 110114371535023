/** 
    before adding css here make sure there's no other solution to overcome the issue,
    read customize theme from antd
**/
.ant-dropdown-menu-submenu-vertical .ant-dropdown-menu-submenu-arrow {
  right: 0 !important;
}

tr.ant-table-row:nth-child(odd) {
  background: #fafafa !important;
}

td.ant-table-column-sort {
  background: inherit !important;
}

.ant-collapse-item {
  margin-bottom: 20px !important;
}

.ant-collapse-header {
  background-color: white;
  max-height: 100px;
  min-height: 100px;
  height: 100px;
}

.ant-collapse-header > :first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.ant-collapse-content-box {
  background-color: white;
  border-top: 1px solid rgb(233, 233, 233);
}

.ant-collapse-extra {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-collapse-header-text {
  height: 100%;
}

.ant-layout-sider-trigger {
  transition: none !important;
  color: rgba(0, 0, 0, 0.8) !important;
  background: transparent !important;
  position: static;
}
